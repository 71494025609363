<script>
  import { DataTableColumnName } from './constants.js';

  export default {
    name: DataTableColumnName,

    inheritAttrs: false,

    /*
    Все props считываются в родительском компоненте `DataTable`.
    Сам компонент ничего не рендерит.
    */
    props: {
      // eslint-disable-next-line vue/no-unused-properties
      id: {
        type: String,
        required: true,
      },
      // eslint-disable-next-line vue/no-unused-properties
      field: {
        type: String,
        required: false,
      },
      // eslint-disable-next-line vue/no-unused-properties
      label: {
        type: String,
        required: false,
      },
      // eslint-disable-next-line vue/no-unused-properties
      widthValue: {
        type: [String, Number],
        required: false,
      },
      // eslint-disable-next-line vue/no-unused-properties
      headClass: {
        type: [String, Object, Array],
        required: false,
      },
      // eslint-disable-next-line vue/no-unused-properties
      bodyClass: {
        type: [String, Object, Array],
        required: false,
      },
      // eslint-disable-next-line vue/no-unused-properties
      headStyle: {
        type: [String, Object, Array],
        required: false,
      },
      // eslint-disable-next-line vue/no-unused-properties
      bodyStyle: {
        type: [String, Object, Array],
        required: false,
      },
    },

    render() {
      return null;
    },
  };
</script>
