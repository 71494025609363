<template>
  <InfoBlock class="country-analytics" gutter="none" noBorder>
    <template v-slot:title>Горячие показатели</template>

    <template v-slot:tools>
      <Multiselect
        class="country-analytics__select"
        v-model="year"
        :options="yearList"
        valueProp="id"
        label="text"
        :canClear="false"
      />
      <RouterLink custom v-slot="{ href, navigate }" :to="{ name: 'countryAnalytics', query: { countryId } }">
        <ButtonComponent type="link" :href="href" @click="navigate"> Аналитика по стране </ButtonComponent>
      </RouterLink>
    </template>

    <TradeIndicatorsList justifyContent="start" class="indicators-list">
      <TradeIndicator
        name="Товарооборот"
        :divider="constants.MILLION"
        :currentValue="currentSummary?.[constants.IndicatorValue.TOTAL_VALUE]"
        :previousPercent="currentSummary?.[constants.IndicatorPreviousPercentValue.TOTAL_VALUE]"
      />
      <TradeIndicator
        name="Экспорт"
        :divider="constants.MILLION"
        :currentValue="currentSummary?.[constants.IndicatorValue.EXPORT_VALUE]"
        :previousPercent="currentSummary?.[constants.IndicatorPreviousPercentValue.EXPORT_VALUE]"
      />
      <TradeIndicator
        name="Импорт"
        :divider="constants.MILLION"
        :currentValue="currentSummary?.[constants.IndicatorValue.IMPORT_VALUE]"
        :previousPercent="currentSummary?.[constants.IndicatorPreviousPercentValue.IMPORT_VALUE]"
      />
      <TradeIndicator
        name="ННЭ"
        :divider="constants.MILLION"
        :currentValue="currentSummary?.[constants.IndicatorValue.EXPORT_NON_ENERGY_VALUE]"
        :previousPercent="currentSummary?.[constants.IndicatorPreviousPercentValue.EXPORT_NON_ENERGY_VALUE]"
      />
      <TradeIndicator
        name="Сальдо"
        :divider="constants.MILLION"
        :currentValue="currentSummary?.[constants.IndicatorValue.BALANCE]"
        :previousPercent="currentSummary?.[constants.IndicatorPreviousPercentValue.BALANCE]"
      />
    </TradeIndicatorsList>

    <div class="country-analytics__chart-container">
      <LineChartFour
        class="country-analytics__chart"
        v-if="graphByYear"
        :key="lineChartKey"
        format-label=" млн $"
        :chart-data="graphByYear.data[1]"
        :chart-data-second="graphByYear.data[1]"
        :chart-data-three="graphByYear.data[2]"
        :chart-data-four="graphByYear.data[3]"
        background-color-three="#2F84EB"
        background-color-four="#31BC00"
        point-color-three="#2F84EB"
        point-color-four="#31BC00"
        background-color="#01A39D"
        point-color="#01A39D"
        background-color-two="#787F8F"
        point-color-two="#787F8F"
        border-color="#01A39D"
        border-color-two="#787F8F"
        border-color-three="#2F84EB"
        border-color-four="#31BC00"
        :labels="graphByYear.years"
        :displayDataLabels="false"
      />
    </div>

    <div class="d-flex flex-wrap column-gap-4 row-gap-2 align-items-center mt-4">
      <div class="indicator-round d-flex align-items-center">
        <span class="rounded-circle"></span>
        <p>Товарооборот</p>
      </div>
      <div class="indicator-round d-flex align-items-center">
        <span class="rounded-circle"></span>
        <p>Экспорт</p>
      </div>
      <div class="indicator-round d-flex align-items-center">
        <span class="rounded-circle"></span>
        <p>Импорт</p>
      </div>
      <div class="indicator-round d-flex align-items-center">
        <span class="rounded-circle"></span>
        <p>Сальдо</p>
      </div>
    </div>
  </InfoBlock>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import InfoBlock from '@/common/components/InfoBlock.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';

  import LineChartFour from '@/components/lineChartFour';

  import CountryАnalyticsApi from '@/modules/countryАnalytics/api';
  import TradeIndicator from '@/modules/countryАnalytics/components/InternationalTrade/indicators/TradeIndicator.vue';
  import TradeIndicatorsList from '@/modules/countryАnalytics/components/InternationalTrade/indicators/TradeIndicatorsList.vue';
  import { DIVIDER_LABEL } from '@/modules/countryАnalytics/utils/constants.js';

  export default {
    name: 'CountryAnalyticsInfoBlock',

    components: {
      Multiselect,
      ButtonComponent,
      InfoBlock,
      LineChartFour,
      TradeIndicator,
      TradeIndicatorsList,
    },

    props: {
      countryId: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        constants: {
          DIVIDER_LABEL,
          IndicatorPreviousPercentValue: Constants.indicator.previousPercentValue,
          IndicatorValue: Constants.indicator.value,
          MILLION: Constants.MILLION,
        },

        yearList: null,
        year: null,
        summary: null,

        // для принудительного ререндера графика при изменении данных
        // https://michaelnthiessen.com/force-re-render/
        lineChartKey: 0,
      };
    },

    computed: {
      currentSummary() {
        return this.summary?.summary;
      },

      graphByYear() {
        const valuesByYears = this.currentSummary?.valuesByYears;

        if (!valuesByYears) {
          return null;
        }

        const indicatorsNames = [
          Constants.indicator.value.TOTAL_VALUE,
          Constants.indicator.value.EXPORT_VALUE,
          Constants.indicator.value.IMPORT_VALUE,
          Constants.indicator.value.BALANCE,
        ];

        return valuesByYears.reduce(
          (acc, yearDataItem) => {
            acc.years.push(yearDataItem.year);
            indicatorsNames.forEach((indicatorsName, index) => {
              const value = (yearDataItem[indicatorsName] / Constants.MILLION).toFixed(0);
              acc.data[index].push(value);
            });
            return acc;
          },
          {
            years: [],
            data: [[], [], [], []],
          },
        );
      },
    },

    methods: {
      resetYear() {
        if (this?.yearList?.length > 0) {
          this.year = this.yearList[this.yearList.length - 1].id;
        }
      },

      async loadYearList() {
        this.yearList = await CountryАnalyticsApi.getYearList(/*для России */ true, /*для товарооборота */ true).then(
          (response) => response.data,
        );

        this.resetYear();
      },

      async loadData() {
        this.summary = await CountryАnalyticsApi.getForeignTradeForOne(
          /*для России */ true,
          /*для товарооборота */ true,
          this.year,
          this.countryId,
        ).then((response) => response.data);

        this.forceRerender();
      },

      forceRerender() {
        this.lineChartKey = 1 - this.lineChartKey;
      },
    },

    async created() {
      await this.loadYearList();
      await this.loadData();
    },

    watch: {
      countryId() {
        this.loadData();
      },

      year() {
        this.loadData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .country-analytics {
  }

  .country-analytics__select {
    margin: 0;
    flex: 0 1 175px;
    width: auto;
  }

  .indicators-list {
    margin-block: 24px;
  }

  .indicator-round {
    span {
      background-color: #018bbf;
      width: 14px;
      height: 14px;
    }
    p {
      margin-left: 8px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }

  .indicator-round:nth-child(1) {
    span {
      background-color: #01a39d;
    }
  }

  .indicator-round:nth-child(2) {
    span {
      background-color: #787f8f;
    }
  }
  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }
  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }

  .country-analytics__chart-container {
    overflow: auto;

    @media (max-width: $laptop) {
      --_country-analytics-info-block-gutter: 14px;
      margin-inline: calc(var(--_country-analytics-info-block-gutter) * -1);
      padding-inline: var(--_country-analytics-info-block-gutter);
    }
  }

  .country-analytics__chart {
    min-width: 700px;
  }
</style>
